import React, { useState } from "react";
import "./NavButton.css";
import { SVGMotionProps, motion } from "framer-motion";
import { pathProps, ModalProps } from "../components/interfaces/general-interfaces";


const Path: React.FC<pathProps & SVGMotionProps<SVGPathElement>> = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="var(--white)"
    strokeLinecap="round"
    {...props}
  />
);

const SVG = (
  <svg width="23" height="23" viewBox="0 0 23 23">
    <Path
      variants={{
        hidden: { d: "M 2 2.5 L 20 2.5" },
        displayed: { d: "M 3 16.5 L 17 2.5" }
      }}
    />
    <Path
      d="M 2 9.423 L 20 9.423"
      variants={{
        hidden: { opacity: 1 },
        displayed: { opacity: 0 }
      }}
      transition={{ duration: 0.1 }}
    />
    <Path
      variants={{
        hidden: { d: "M 2 16.346 L 20 16.346" },
        displayed: { d: "M 3 2.5 L 17 16.346" }
      }}
    />
  </svg>
);




const NavButton: React.FC<ModalProps> = ({ showModal, toggleModal }) => {
  const displayed = showModal;

  return (
    <motion.button
      onClick={toggleModal}
      whileHover={{
        scale: 1.4,
        transition: {
          yoyo: 100
        }
      }}
      animate={displayed ? "displayed" : "hidden"}
      className="nav-button">
        {SVG}
    </motion.button>
  );
}
export default NavButton;